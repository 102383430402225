jQuery(document).ready(function($) {
    $(".broker-check").addClass("show");
    if (Cookies.get('broker-check')) {

        $(".broker-check").removeClass("show");
        $(".broker-check").addClass("hide");
    }

    $(".broker-check button").click(function() {
        // Set a cookie site-wide that expires in 24 hours
        Cookies.set('broker-check', 'true', { expires: 1 });

        // Hide the element
        $(".broker-check").addClass("hide");
        $(".broker-check").removeClass("show");
    });
});
